import React from 'react'
import Layout from '../../layout'
import SideMenu from '../../sideMenu/side-menu'
import { getTranslateFunction } from '../../../utilities'
/* import ContactForm from '../../contactForm/ContactForm' */
import Img from 'gatsby-image'
import Tilt from 'react-tilt'
import PageTitle from '../../pageTitle'
import Reveal from '../../reveal'
import Testimonials from '../../testimonials/Testimonials'
import '../Pages.scss';
import './OurClientTemplate.scss';
import ContactUs from '../../contactUs/ContactUs'


const OurClientsTemplate = ({ data, lang }) => {

  const ourWork = data.ourWork.childMarkdownRemark.frontmatter
  const references = data.saidAboutUs.childMarkdownRemark.frontmatter.references

  const t = getTranslateFunction(lang)

  return (
    <Layout lang={lang} className={'mobile-menu-offset our-clients'}>
      <section className="about-us-top margin-top">
        <div className="container container-to-xxl-off">
          <div className="row subpage-title align-items-center fix-nav-offset">
            <div className="col-lg-8 col-xxl-11 push-xxl-none">
              <PageTitle title={ourWork.title} description={ourWork.description} />
              <p>{ourWork.subtitle}</p>
            </div>
            <div className="col-lg-8 col-xxl-11 push-xxl-none">
              <p className="description-smaller pr-0">{ourWork.description}</p>
            </div>
          </div>
        </div>
      </section>

      <Reveal>
        <section className="references">
          <div className="d-md-flex">
            <div className="d-none d-lg-block col-md-3 side-menu-height-reference" id="left">
              <SideMenu lang={lang} isGhost={false} />
            </div>
            <div>
              {/* Testimonial */}
              <Testimonials references={references} addContainer={false} enableSaidAboutUs={false} sliderClassName='mt-0' className="pt-0 mt-5" />
              <div className="col-lg-11 push-lg-0 mt-5 mt-md-0">
                <div className="about-us-clients">
                  <div className="row">
                    <div className="col ml-auto">
                      <h2>{t('OurWork.LogosTitle')}</h2>
                      <div className="row align-items-center justify-content-center">
                        {ourWork.logos.map((logo, i) => {
                          return (
                            <div className="col-6 col-sm-3 col-md-4 mb-4 col-xl-2" key={i}>
                              <Tilt
                                className={'tilt'}
                                options={{
                                  max: 25,
                                  glare: true,
                                  'max-glare': 0.5,
                                  reverse: true,
                                  scale: 1.05,
                                }}>
                                <Img fluid={logo.href.childImageSharp.fluid} alt={logo.alt} title={logo.alt} />
                              </Tilt>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal>
        <ContactUs
          blockClassName={'services'}
          LeftSideTitle={t('References.MoreProjects.Title')}
          LeftSideSubTitle={'Ako pracujeme'}
          LeftSideLink={'/o-nas/ako-pracujeme'}
          linkRight="/en/contact"
          rightSecondLink="/en/brief"
        />
      </Reveal>

   {/*    <Reveal>
        <ContactForm />
      </Reveal> */}
    </Layout>)
}

export default OurClientsTemplate
