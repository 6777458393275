import React from 'react'
import OurClientsTemplate from '../../components/pages/ourClientTemplate/our-clients-template'
import { graphql } from 'gatsby'

const OurClients = ({ data }) => (<OurClientsTemplate data={data} lang={'sk'}/>)

export const query = graphql`{
    ourWork: file(relativePath: {eq: "pages/sk/about-us/our_work.md"}) {
        childMarkdownRemark {
            frontmatter {
                title
                subtitle
                description
                logos {
                    href {
                        childImageSharp {
                            fluid( maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    alt
                }
            }
        }
    }
    saidAboutUs: file(relativePath: {eq: "pages/sk/homepage/said_about_us.md"}) {
        childMarkdownRemark {
            frontmatter {
                text_paragraph
                references {
                    description
                    logo: logo {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    logoBig: logo {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    author
                }
            }
        }
    }
}
`


export default OurClients
